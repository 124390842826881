import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CallMutation, CallAPI } from 'src/utils/API/APIConfig';
import { useSnackbar } from 'src/components/snackbar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [responseType, setResponseType] = useState(null);
  const [cart, setCart] = useState({
    items: [],
    comment: '',
    shippingAddressId: 0,
    ongoingItems: [],
  });

  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { refetch: refetchCartCount } = CallAPI({
    name: 'getCartCount',
    url: '/Cart/get-cart',
    baseURL: `${process.env.REACT_APP_DOMAIN}`,

    refetchOnWindowFocus: true,
    enabled: true,
    method: 'get',
    select: (data) => data?.data?.data,
    onSuccess: (res) => {
      setCartCount(res?.itemCount);
    },
  });

  const addToCart = CallMutation({
    url: 'Cart/add-to-cart',
    method: 'put',
    onMutate:()=> resetResponseType(),
    onSuccess: (res) => {
      if (res.data.success) {
        enqueueSnackbar(translate('cart_updated'));
        setCartCount(res?.data?.data?.itemCount);
        setResponseType('Success');
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' });
        setResponseType('Fail');
      }
      setLoading(false);
    },
    onError: (res) => {
      if (res?.response?.data?.code === 522) {
        if(JSON.parse(res.response.config.data).isMarketplaceAdd){
          enqueueSnackbar(translate('product_not_found'), { variant: 'error' });
        }else{
          navigate(PATH_DASHBOARD.ProductNotFound, { replace: true });
        }
      } else {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
      setResponseType('Fail');
      setLoading(false);
    },
  });

  const resetResponseType=()=>setResponseType(null);

  const memoizedValue = useMemo(
    () => ({
      cart,
      setCart,
      addToCart,
      cartCount,
      setCartCount,
      refetchCartCount,
      loading,
      setLoading,
      responseType,
    }),
    [cart, setCart, addToCart, cartCount, setCartCount, refetchCartCount, loading, setLoading, responseType,]
  );
  return <CartContext.Provider value={memoizedValue}>{children}</CartContext.Provider>;
};

CartProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CartProvider;
