import { Navigate, Routes, Route } from 'react-router-dom';
import { Suspense, ElementType } from 'react';
import CartProvider from 'src/utils/Contexts/Cart/CartProvider';
import NotificationsProvider from 'src/utils/Contexts/Notifications/NotificationsProvider';
import CategoriesProvider from 'src/utils/Contexts/Categories/CategoriesProvider';
import GuestCategoriesProvider from 'src/utils/Contexts/Categories/GuestCategoriesProvider';
import { useAuthContext } from 'src/auth/useAuthContext';
import { isValidToken } from 'src/auth/utils';
import { checkRefreshTokenExpirationValid } from 'src/utils/API/Helpers';
import useResponsive from 'src/hooks/useResponsive';
import MobileView from 'src/components/mobileView';
import LoadingScreen from '../components/loading-screen';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import GuestLayout from '../layouts/dashboard/GuestLayout';
import {
  Page404,
  MarketPlace,
  LoginPage,
  Orders,
  Wforders,
  OrderDetails,
  RegisterPage,
  MobileVerificationPage,
  AllProduct,
  DefaultInvoiceList,
  Reports,
  ReorderingLists,
  SmartStockList,
  SmartStockAddPage,
  SmartStockItemDetails,
  UserProfile,
  CompanyTabs,
  MarketPlaceProductDetailsPage,
  ChooseCompany,
  GeneralSearchList,
  ListItemDetails,
  CartDetails,
  ConfirmationOrderScreen,
  PrivacyPolicy,
  ProductNotFound,
  Notifications,
  Catalog,
  RFQList,
  RFQForm,
  RFQDetails,
  RFQPayment,
  AccessDenied,
  EssentialsPack,
  ProductsCategory,
  ProductsSection,
  InvoicePayment,
  InvoicesDetails,
  GuestMarketPlace,
  GuestProductDetailsPage,
  GuestProductsCategory,
  GuestEssentialsPack,
  GuestProductsSection,
  GuestGeneralSearchList,
  DevliveryNoteSigning,
  AnalyticsTagsList,
  DevliveryNoteDownload,
  MyCatalog,
  ProductsStore,
  GuestProductsStore,
  MobileSettings,
  AddressListMobileView,
  UserProfileMobileView,
  Orderslist,
  OrderDetailsApprovel,
  OrderDetailsMobileView,
  CompanyListMobileView,
  FinancialInformationMobile,
  ReorderingAddPage,
  CategoriesMobileView,
  ListItemDetailsMobileView,
  CategoriesGuestMobileView,
  RFQChat,
  RFQDetailsMobile,
  MyCatalogMobile,
  GuestMobileSettings,
  AllProductGuest,
  SubscriptionManagement,
  SubscriptionMobileView,
  AddSubscriptionMobileView,
  OrderDetailsApprovelMobileView,
  MyCompanyContractList,
  MyCompanyContractListDetailes,
  MyCompanyContractListDetailesMobile,
  QuotationsList,
  QuotationDetails,
  QuotationPayment,
  ReturnRequestMobile,
  AutoLoginPunchout,
} from './elements';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const IsAuth = () => {
  const { changeCompanyRequired } = useAuthContext();
  const accessToken = localStorage?.getItem('accessToken');
  return (
    accessToken &&
    (isValidToken(accessToken || '') || checkRefreshTokenExpirationValid() === true) &&
    !changeCompanyRequired
  );
};

export const PrivateRoutes = () => {
  const auth = IsAuth();

  const allowGuestMode = process.env.REACT_APP_ALLOW_GUEST_MODE === 'true';

  if (auth) {
    return (
      <AuthGuard>
        <CartProvider>
          <NotificationsProvider>
            <CategoriesProvider>
              <DashboardLayout />
            </CategoriesProvider>
          </NotificationsProvider>
        </CartProvider>
      </AuthGuard>
    );
  }
  if (allowGuestMode) {
    return (
      <GuestGuard>
        <GuestCategoriesProvider>
          <GuestLayout />
        </GuestCategoriesProvider>
      </GuestGuard>
    );
  }
  return <Navigate to="/login" />;
};

export default function Router() {
  const auth = IsAuth();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={auth ? <MarketPlace /> : <GuestMarketPlace />} path="/" />

        <Route element={<Navigate to="/" />} path="marketplace" />
        <Route
          element={auth ? <MarketPlaceProductDetailsPage /> : <GuestProductDetailsPage />}
          path="products/:sku"
        />
        <Route
          element={auth ? <GeneralSearchList /> : <GuestGeneralSearchList />}
          path="search/:results"
        />
        <Route
          element={auth ? <ProductsCategory /> : <GuestProductsCategory />}
          path="categories/:id"
        />
        <Route
          element={auth ? <ProductsSection /> : <GuestProductsSection />}
          path="categories/section/:id"
        />
        <Route
          element={auth ? <ProductsStore /> : <GuestProductsStore />}
          path="categories/store/:id"
        />
        <Route
          element={auth ? <EssentialsPack /> : <GuestEssentialsPack />}
          path="categories/EssentialsPack"
        />
        <Route
          element={auth ? <CategoriesMobileView /> : <CategoriesGuestMobileView />}
          path="categories"
        />
        <Route element={<ProductNotFound />} path="/productnotfound" />
        <Route element={<AccessDenied />} path="Accessdenied" />
        <Route path="invoices">
          <Route element={auth ? <DefaultInvoiceList /> : <Navigate to="/" />} index />
          <Route element={auth ? <InvoicePayment /> : <Navigate to="/" />} path="payment/:id" />
          <Route element={auth ? <InvoicesDetails /> : <Navigate to="/" />} path=":id" />
        </Route>
        {isDesktop && <Route element={auth ? <Reports /> : <Navigate to="/" />} path="reports" />}

        {!isDesktop && <Route element={auth ? <MobileView /> : <Navigate to="/" />} path="reports" />}
        {isDesktop && <Route element={auth ? <MyCatalog /> : <Navigate to="/" />} path="catalog" />}
        {!isDesktop && (
          <Route element={auth ? <MyCatalogMobile /> : <Navigate to="/" />} path="catalog" />
        )}
        {isDesktop && <Route element={auth ? <SubscriptionManagement /> : <Navigate to="/" />} path="subscription-management" />}
        <Route path="management-ContractList">
          <Route element={auth ? <MyCompanyContractList /> : <Navigate to="/" />} index />
        { isDesktop && <Route element={auth ? <MyCompanyContractListDetailes /> : <Navigate to="/" />} path=":id" />}
        { !isDesktop && <Route element={auth ? <MyCompanyContractListDetailesMobile /> : <Navigate to="/" />} path=":id" />}

        </Route>
        {!isDesktop && <Route element={auth ? <SubscriptionMobileView /> : <Navigate to="/" />} path="subscription-management" />}
        {!isDesktop && <Route element={auth ? <AddSubscriptionMobileView /> : <Navigate to="/" />} path="subscription-management/add" />}

        <Route path="orders"> 
          <Route path="all" >
            <Route element={auth ? <Orders /> : <Navigate to="/" />} index />
            {isDesktop && (
              <Route element={auth ? <OrderDetails /> : <Navigate to="/" />} path=":id" />
            )}
            {!isDesktop && (
              <Route element={auth ? <OrderDetailsMobileView /> : <Navigate to="/" />} path=":id" />
            )}
          </Route>
          <Route path="Wforders">
            <Route element={auth ? <Wforders /> : <Navigate to="/" />} index />
            {isDesktop && (
              <Route element={auth ? <OrderDetailsApprovel /> : <Navigate to="/" />} path=":id" />
            )}
            {!isDesktop && (
              <Route
                element={auth ? <OrderDetailsApprovelMobileView /> : <Navigate to="/" />}
                path=":id"
              />
            )}

          </Route>
          <Route path="saved_carts">
            <Route element={auth ? <QuotationsList /> : <Navigate to="/" />} index />
            <Route element={auth ? <QuotationDetails /> : <Navigate to="/" />} path=":id" />
            <Route element={auth ? <QuotationPayment /> : <Navigate to="/" />} path="payment/:id" />

          </Route>
          
          <Route path="return">
            <Route element={auth ? <ReturnRequestMobile /> : <Navigate to="/" />} path=":id" />
          </Route>
        </Route>

        <Route path="reorderingLists">
          <Route element={auth ? <ReorderingLists /> : <Navigate to="/" />} index />
          <Route element={auth ? <ReorderingAddPage /> : <Navigate to="/" />} path="AddList" />
          {isDesktop && (
            <Route element={auth ? <ListItemDetails /> : <Navigate to="/" />} path=":id" />
          )}
          {!isDesktop && (
            <Route
              element={auth ? <ListItemDetailsMobileView /> : <Navigate to="/" />}
              path=":id"
            />
          )}
        </Route>

        <Route
          element={auth ? <AnalyticsTagsList /> : <Navigate to="/" />}
          path="AnalyticsTagsList"
        />

        {isDesktop && (
          <Route element={auth ? <UserProfile /> : <Navigate to="/" />} path="userprofile" />
        )}
        <Route element={auth ? <CompanyTabs /> : <Navigate to="/" />} path="companyprofile" />
        <Route element={auth ? <MobileSettings /> : <GuestMobileSettings />} path="settings" />
        <Route element={auth ? <AddressListMobileView /> : <Navigate to="/" />} path="address" />
        <Route element={auth ? <CompanyListMobileView /> : <Navigate to="/" />} path="companies" />
        <Route
          element={auth ? <FinancialInformationMobile /> : <Navigate to="/" />}
          path="financial"
        />
        {!isDesktop && (
          <Route element={auth ? <UserProfileMobileView /> : <Navigate to="/" />} path="profile" />
        )}
        <Route element={auth ? <CategoriesMobileView /> : <Navigate to="/" />} path="categories" />
        <Route
          element={auth ? <CategoriesGuestMobileView /> : <Navigate to="/" />}
          path="categories"
        />
        <Route element={auth ? <AllProduct /> : <AllProductGuest />} path="/all-products" />

        <Route element={auth ? <CartDetails /> : <Navigate to="/" />} path="cart" />
        <Route
          element={auth ? <ConfirmationOrderScreen /> : <Navigate to="/" />}
          path="order_confirmation"
        />
        <Route element={auth ? <Notifications /> : <Navigate to="/" />} path="notifications" />
        <Route path="request_for_quotation">
          <Route element={auth ? <RFQList /> : <Navigate to="/" />} index />

          {isDesktop && <Route element={auth ? <RFQDetails /> : <Navigate to="/" />} path=":id" />}
          {!isDesktop && (
            <Route element={auth ? <RFQDetailsMobile /> : <Navigate to="/" />} path=":id" />
          )}
          <Route element={auth ? <RFQForm /> : <Navigate to="/" />} path="create" />
          <Route element={auth ? <RFQPayment /> : <Navigate to="/" />} path="payment/:id" />
          <Route element={auth ? <RFQChat /> : <Navigate to="/" />} path="chat" />
        </Route>
        <Route element={<Navigate to="/404" replace />} path="*" />
      </Route>
      <Route
        element={
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        }
        path="/login"
      />
      <Route
        element={
          <GuestGuard>
            <MobileVerificationPage />
          </GuestGuard>
        }
        path="otp"
      />
      <Route
        element={
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        }
        path="/register"
      />
      <Route element={<DevliveryNoteSigning />} path="/ns" />
      <Route element={<DevliveryNoteDownload />} path="/ds" />
      <Route element={<CompactLayout />}>
        <Route element={<Page404 />} path="/404" />

        <Route element={<PrivacyPolicy />} path="privacy-policy" />
        <Route element={<ChooseCompany />} path="choose_company" />
      </Route>
      <Route element={<Catalog />} path="catalog" />
      <Route
        element={
          <GuestGuard>
            <AutoLoginPunchout />
          </GuestGuard>
        }
        path="/punchout"
      />
      <Route element={<Navigate to="/404" replace />} path="*" />
      <Route path="smart-stock-list">
        <Route element={auth ? <SmartStockList /> : <Navigate to="/" />} index />
        <Route element={auth ? <SmartStockAddPage /> : <Navigate to="/" />} path="add-list" />
        <Route element={auth ? <SmartStockItemDetails /> : <Navigate to="/" />} path=":id" />
      </Route>
    </Routes>
  );
}
