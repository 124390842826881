import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { CategoriesContext } from 'src/utils/Contexts/Categories/CategoriesProvider';
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import packIcon from 'src/assets/icons/packs.png';
import useActiveLink from '../../../hooks/useActiveLink';
import { NavListProps } from '../types';
import NavItem from './NavItem';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  isCategoryItem?: boolean;
  isRfqItem?: boolean;
  isMyCatalogItem?: boolean;
  hasInventory?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChild,
  isCategoryItem,
  isRfqItem,
  isMyCatalogItem,
  hasInventory,
}: NavListRootProps) {
  const { pathname } = useLocation();
  const { active, isExternalLink } = useActiveLink(data.path, data.deep);
  const [open, setOpen] = useState(active);
  const { AuthorizationInfo } = useContext(UserContext);
  const { CategoriesPath } = useContext(CategoriesContext);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const hideOrShowNavItem =
    (!isRfqItem || (isRfqItem && AuthorizationInfo?.isRFQEnabled)) &&
    (!isMyCatalogItem || (isMyCatalogItem && AuthorizationInfo?.hasCatalog)) &&
    (!hasInventory || (hasInventory && AuthorizationInfo?.hasInventory));
  return (
    <>
      {hideOrShowNavItem && (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={handleToggle}
        />
      )}

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
      {isCategoryItem && (
        <Collapse in={open} unmountOnExit>
          <CategorySubList data={CategoriesPath} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}

type NavCatListSubProps = {
  data: NavListProps[];
  depth: number;
};

function CategorySubList({ data, depth }: NavCatListSubProps) {
  const { translate } = useLocales();
  const essentialsPack = {
    id: '-1',
    title: `${translate('starter_pack')}`,
    imageUrl: packIcon,
    path: `${PATH_DASHBOARD.categories.EssentialsPack}`,
  };
  return (
    <>
      <NavList key="essentialsPack" data={essentialsPack} depth={depth + 1} hasChild={false} />
      {data?.map((list) => (
        <NavList
          key={`cat${list?.name}${list?.id}`}
          data={list}
          depth={depth + 1}
          hasChild={false}
        />
      ))}
    </>
  );
}
