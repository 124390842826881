import { browserName, fullBrowserVersion, osName, osVersion } from 'react-device-detect';

export function capitalizeKeys<T>(obj: T): T {
  // @ts-ignore
  return Object.keys(obj).reduce((acc, key) => {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    // @ts-ignore
    (acc as any)[capitalizedKey] = obj[key];
    return acc;
  }, {} as T);
}

export function getBrowserInfo() {
  return `browser name = ${browserName}, browser version = ${fullBrowserVersion}
  os name = ${osName}, os version = ${osVersion}`;
}

export function getAppEnvironment() {
  const URL = window.location.host;
  const subdomain = URL.split('.')[0];
  let environment;

  if (subdomain === 'dev-new') {
    environment = 'development';
  }
  if (subdomain === 'stage') {
    environment = 'staging';
  }
  return environment;
}

export function getRedirectUrl(){
  const allowGuestMode = process.env.REACT_APP_ALLOW_GUEST_MODE === 'true';
  return allowGuestMode ? window.location.origin : 'https://www.lawazem.com/';
}

export function truncateName(name: string, nameLength: number) {
  if (name.length > nameLength) {
    return `${name.slice(0, nameLength)  }...`;
  }
  return name;
}