import { Link as RouterLink } from 'react-router-dom';
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { actionNameTypes } from 'src/utils/Enums';
import { useLocales } from '../../../locales';
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import { RightIcon } from './Icon';

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate, currentLang, onChangeLang } = useLocales();
  const { title, path, icon, info, children, disabled, caption, roles, action_name = '' } = item;

  const subItem = depth !== 1;
  const theme = useTheme();

  const isRTL = theme.direction === 'rtl';
  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${translate(title)}`}
        secondary={
          caption && (
            <Tooltip title={`${translate(caption)}`} placement="top-start">
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <RightIcon
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          isRTL={isRTL}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    if (action_name === actionNameTypes.lang) {
      return (
        <Link
          component={Link}
          sx={{ '&:hover': { textDecoration: 'none' } }}
          onClick={() => onChangeLang(currentLang.value === 'en' ? 'ar' : 'en')}
        >
          {renderContent}
        </Link>
      );
    }

    // open chat
    if (action_name === actionNameTypes.openChat) {
      return (
        <Link
          component={Link}
          sx={{ '&:hover': { textDecoration: 'none' } }}
          onClick={() => {
            window.fcWidget.setConfig({ fullscreen: true });
            window.fcWidget.open();
          }}
        >
          {renderContent}
        </Link>
      );
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
